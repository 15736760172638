import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MobileResolutionService {

  constructor() {}

  public getIsMobileResolution(): boolean {
	if (window.innerWidth < 768) {
      return true;
    } else {
      return false;
    }  
  }
}
