export class Subscriber {

    private subscriberName: string;
    private subscriberEmail: string;

	public setSubscriberName(subscriberName: string): void {
        this.subscriberName = subscriberName;
    }

    public getSubscriberName(): string {
        return this.subscriberName;
    }

	public setSubscriberEmail(subscriberEmail: string): void {
        this.subscriberEmail = subscriberEmail;
    }

    public getSubscriberEmail(): string {
        return this.subscriberEmail;
    }
}
