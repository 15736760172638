import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscriber } from '../models/subscriber';

@Injectable()
export class AddSubscriberService {

    private SUBSCRIBERS_PATH = "http://akinsterbooks.co.uk:81/service/entities/subscribers";

	constructor(private httpClient: HttpClient) { }
	
	public addSubscriber(subscriber: Subscriber) {
		return this.httpClient.post(this.SUBSCRIBERS_PATH, subscriber);
	}
}
