import { AskAkinsterComponent } from './components/ask-akinster/ask-akinster.component';
import { AuthorsComponent } from './components/authors/authors.component';
import { BlogComponent } from './components/blog/blog.component';
import { BooksComponent } from './components/books/books.component';
import { BookDetailsComponent } from './components/book-details/book-details.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubscribeComponent } from './components/subscribe/subscribe.component';

const routes: Routes = [
  {
    path: 'books',
    component: BooksComponent
  },
  {
    path: 'authors',
    component: AuthorsComponent
  },
  {
    path: 'blog',
    component: BlogComponent
  },
  {
    path: 'book-details',
    component: BookDetailsComponent
  },
  {
    path: '',
    pathMatch: 'full',
    component: AskAkinsterComponent
  },
  {
    path: 'subscribe',
    component: SubscribeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
