import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ask-akinster',
  templateUrl: './ask-akinster.component.html',
  styleUrls: ['./ask-akinster.component.sass']
})
export class AskAkinsterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
