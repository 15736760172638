import { Component, OnInit } from '@angular/core';
import { MobileResolutionService } from '../../services/mobile-resolution.service';

@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.sass']
})
export class BooksComponent implements OnInit {

  public isMobile: boolean;
  
  constructor(private mobileResolutionService: MobileResolutionService) { 
	this.isMobile = this.mobileResolutionService.getIsMobileResolution();
  }

  ngOnInit() {
  }
  
}
