import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/RX';
import { Subject } from 'rxjs/RX';

@Injectable()
export class SelectedBookService {
	
	private selectedBookObservable;
	
	private selectedBookSubject = new Subject<any>();
	
	private currentSelectedBook: any;

	constructor() { 
		this.selectedBookObservable = this.selectedBookSubject.asObservable();
	}
	
	public setSelectedBook(book: any) : void {
		this.selectedBookSubject.next(book);
		this.currentSelectedBook = book;
	}
	
	public getCurrentSelectedBook(): any {
		return this.currentSelectedBook;
	}
	
	public getSelectedBookObservable() : Observable<any> {
		return this.selectedBookObservable;
	}
}
