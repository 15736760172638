import { Component, OnInit } from '@angular/core';
import { AddSubscriberService } from '../../services/add-subscriber-service';
import { Subscriber } from '../../models/subscriber';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.sass']
})
export class SubscribeComponent implements OnInit {
	
  public subscriberName: string = "";
  public subscriberEmail: string = "";  

  constructor(private addSubscriberService: AddSubscriberService) { }

  ngOnInit() {
  }
  
  public isSubscribeEnabled(): boolean {
	if (this.subscriberName == null || this.subscriberName == undefined || this.subscriberName == '') {
		return false;
	}
	
	if (this.subscriberEmail == null || this.subscriberEmail == undefined || this.subscriberEmail == '') {
		return false;
	}
	
	return true;
  }
  
  public addSubscriber(): void {
	let subscriber: Subscriber = new Subscriber();
	subscriber.setSubscriberName(this.subscriberName);
	subscriber.setSubscriberEmail(this.subscriberEmail);
	this.addSubscriberService.addSubscriber(subscriber).subscribe(
		(response) => {
			alert( "New subscription has been created" );
			this.subscriberName = "";
			this.subscriberEmail = "";
		},
		error => { alert( "Unable to create new subscription: " + error );});
  }

}
