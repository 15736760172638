import { AddSubscriberService } from './services/add-subscriber-service';
import { AppComponent, SafePipe } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AskAkinsterComponent } from './components/ask-akinster/ask-akinster.component';
import { AuthorsComponent } from './components/authors/authors.component';
import { BookDetailsComponent } from './components/book-details/book-details.component';
import { BooksComponent } from './components/books/books.component';
import { BlogComponent } from './components/blog/blog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { GetEntitiesService } from './services/get-entities-service';
import { HeaderComponent } from './components/header/header.component';
import { HttpClientModule } from '@angular/common/http';
import { MobileResolutionService } from './services/mobile-resolution.service';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from "ngx-spinner";
import { SelectedBookService } from './services/selected-book-service';
import { SubscribeComponent } from './components/subscribe/subscribe.component';
import { BookListingsComponent } from './components/book-listings/book-listings.component';
import { BlogViewComponent } from './components/blog-view/blog-view.component';

@NgModule({
  declarations: [
    AppComponent,
    AskAkinsterComponent,
    AuthorsComponent,
    BlogComponent,
	BookDetailsComponent,
    BooksComponent,
	HeaderComponent,
    SafePipe,
	SubscribeComponent,
	BookListingsComponent,
	BlogViewComponent
  ],
  imports: [
    AppRoutingModule,
	BrowserAnimationsModule,
    BrowserModule,
	FormsModule,
	HttpClientModule,
	NgxSpinnerModule
  ],
  providers: [
	AddSubscriberService, 
	GetEntitiesService,
	MobileResolutionService,
	SelectedBookService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
