import { Component, OnInit } from '@angular/core';
import { GetEntitiesService } from '../../services/get-entities-service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.sass']
})
export class BlogComponent implements OnInit {
	
  public blogs: any[];		

  constructor(private getEntitiesService: GetEntitiesService, private spinner: NgxSpinnerService) {
	this.loadBlogs();
  }

  ngOnInit() {
  }
  
  private loadBlogs(): void {
	this.spinner.show();  
	this.getEntitiesService.getEntities("blogs").subscribe(
		(response) => {
			this.blogs = (<any[]> response).sort((a,b) => (a.entryDate > b.entryDate ? -1 : 1));
			this.spinner.hide();
		},
		error => { 
			alert( "Unable to load blogs: " + error );
			this.spinner.hide();
		});
  }

}
