import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class GetEntitiesService {

    private ENTITIES_PATH_PREFIX = "http://akinsterbooks.co.uk:81/service/entities/";

	constructor(private httpClient: HttpClient) { }
	
	public getEntities(entityType: string) {
		return this.httpClient.get(this.ENTITIES_PATH_PREFIX + entityType);
	}
}
