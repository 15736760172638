import { Component, OnInit } from '@angular/core';
import { GetEntitiesService } from '../../services/get-entities-service';
import { MobileResolutionService } from '../../services/mobile-resolution.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { SelectedBookService } from '../../services/selected-book-service';

@Component({
  selector: 'app-book-listings',
  templateUrl: './book-listings.component.html',
  styleUrls: ['./book-listings.component.sass']
})
export class BookListingsComponent implements OnInit {

  public books: any[];
  
  public isMobile: boolean;
  
  constructor(private getEntitiesService: GetEntitiesService, private spinner: NgxSpinnerService, private selectedBookService: SelectedBookService, private mobileResolutionService: MobileResolutionService, private router: Router) { 
	this.isMobile = this.mobileResolutionService.getIsMobileResolution();
	this.loadBooks();
  }

  ngOnInit() {
  }
  
  public setSelectedBook(selectedBook: any): void {
	  if ( this.isMobile ) {
		  this.router.navigate(['/book-details']);
	  }
	  
	  this.selectedBookService.setSelectedBook(selectedBook);
  }
  
  private loadBooks(): void {
	this.spinner.show();
	this.getEntitiesService.getEntities("books").subscribe(
		(response) => {
			this.books = <any[]> response;
			this.spinner.hide();
		},
		error => { 
			alert( "Unable to load books: " + error );
			this.spinner.hide();
		});
  }

}
