import { Component, OnInit } from '@angular/core';
import { MobileResolutionService } from '../../services/mobile-resolution.service';
import { Router } from '@angular/router';
import { SelectedBookService } from '../../services/selected-book-service';

@Component({
  selector: 'app-book-details',
  templateUrl: './book-details.component.html',
  styleUrls: ['./book-details.component.sass']
})
export class BookDetailsComponent implements OnInit {
	
  public selectedBook: any;	
    
  public selectedBookRetailers: any[];
  
  public selectedBookTrailerLink: string;

  constructor(private selectedBookService: SelectedBookService, private mobileResolutionService: MobileResolutionService, private router: Router) { 
    if (this.isMobile()) {
	    this.selectedBook = this.selectedBookService.getCurrentSelectedBook();
		this.selectedBookRetailers = this.selectedBook.bookRetailers;
		this.selectedBookTrailerLink = this.selectedBook.trailerLink;
	} else {
		this.subscribeToSelectedBooks();
	}
  }

  ngOnInit() {
  }
  
  public isMobile() : boolean {
	  return this.mobileResolutionService.getIsMobileResolution();
  }
  
  public goToBookBrowser(): void {
	  this.router.navigate(['/books']);
  }
  
  private subscribeToSelectedBooks() : void {
	this.selectedBookService.getSelectedBookObservable().subscribe( nextSelectedBook => {
		this.selectedBook = nextSelectedBook;
		this.selectedBookRetailers = this.selectedBook.bookRetailers;
		this.selectedBookTrailerLink = this.selectedBook.trailerLink;
	});
  }

}
