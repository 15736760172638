import { Component, OnInit } from '@angular/core';
import { GetEntitiesService } from '../../services/get-entities-service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-authors',
  templateUrl: './authors.component.html',
  styleUrls: ['./authors.component.sass']
})
export class AuthorsComponent implements OnInit {
	
  public authors: any[];	

  constructor(private getEntitiesService: GetEntitiesService, private spinner: NgxSpinnerService) { 
	this.loadAuthors();
  }
  
  ngOnInit() {
  }
  
  private loadAuthors(): void {
	this.spinner.show();  
	this.getEntitiesService.getEntities("authors").subscribe(
		(response) => {
			this.authors = <any[]> response;
			this.spinner.hide();
		},
		error => { 
			alert( "Unable to load authors: " + error );
			this.spinner.hide();
		});
  }

}
